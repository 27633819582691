import * as React from "react";
import Layout from "../components/layout";

import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import Helmet from "react-helmet";

export default function Index() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Alex McPhee</title>
        <meta name="description" content="Independent cartographer based in Grasslands National Park, Canada." />
        <meta name="image" content="https://awmcphee.ca/headshot.jpg" />
        <meta property="og:image" content="https://awmcphee.ca/headshot.jpg"/>
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:site" content="@ksituan"/>
        <meta name="twitter:title" content="Alex McPhee"/>
        <meta name="twitter:description" content="Independent cartographer based in Grasslands National Park, Canada."/>
        <meta name="twitter:image" content="https://awmcphee.ca/headshot.jpg"/>
      </Helmet>
      <div className="twoColumns">
      <StaticImage
        className="map"
        src="../images/me_2023.jpg"
        alt="Portrait of Alex."
      />
      <div>
        <h1>Howdy!</h1>
      <p>
        I'm an independent cartographer based in Val Marie, Saskatchewan.
        It's as fun as it sounds! I publish maps, accept contracts, and do custom work in print and online.
        If you want to hire me, find everything you need <Link to="/freelance">here</Link>.
      </p>
      <p>I specialize in producing really excellent wall maps, and this website is the <strong>only</strong> place to buy them online.
      If you're here to place an order, then skip straight ahead to my <Link to="/store">online checkout</Link>. For more information and high-resolution preview images, keep reading!</p>
      </div>
      </div>

      <h2>Reference Maps</h2>
      <p>The gold standard of wall maps. Internationally recognized, Canadian printed, competitively priced. Start your tradition of cartographic excellence today:</p>
      <div className="refmaps" style={{display: "flex", maxWidth: "1200px", margin: "2rem auto", justifyContent: "center", gap: "2rem"}}>
        <Link to="/alberta" className="refmap">
          <div className="refpic">
            <StaticImage height={340} src="../images/50dpi.jpg" alt="A finely crafted map of Alberta." />
            <div className="mapdim mapwidth">42”</div>
            <div className="mapdim mapheight">68”</div>
          </div>
          <div>
            <h4>Alex McPhee's Alberta</h4>
            <p>The best in its class.</p>
            <p className="linkButton" to="/alberta">Starting at $59.99</p>
            <ul>
              <li>Highly Commended (BCS, Stanfords Award, 2021)</li>
              <li>Best Reference Map (Cartography and GISociety, 2020)</li>
              <li>Best in Design (Student) (NACIS, 2019)</li>
            </ul>
          </div>
        </Link>
        <Link to="/saskatchewan" className="refmap">
          <div className="refpic">
            <StaticImage height={360} src="../images/saskatchewan.jpg" alt="A finely crafted map of Saskatchewan." />
            <div className="mapdim mapwidth">36”</div>
            <div className="mapdim mapheight">66”</div>
          </div>
          <div>
            <h4>Alex McPhee's Saskatchewan</h4>
            <p><strong>Second edition available!</strong></p>
            <p className="linkButton">Starting at $59.99</p>
            <ul>
              <li>Every grain elevator</li>
              <li>Every grid road</li>
              <li>Hutterite colonies</li>
              <li>Treaty boundaries</li>
              <li>Reserve surrenders</li>
            </ul>
          </div>
        </Link>
      </div>

      <p>What's next? Saskatchewan becoming a best-seller has diverted a lot of my creative time into sales, but I'm not complaining. Expect <b>Manitoba</b> and <b>Canada</b> (with an emphasis on modern treaties) in, perhaps, 2025.</p>

      <h2>Election Maps</h2>
      <p><strong>By popular demand.</strong> Politics is confusing enough, get the election maps that show you what's really going on. Perfect for armchair academics and public advocates.</p>

      <div className="refmaps" style={{display: "flex", maxWidth: "1200px", margin: "2rem auto", justifyContent: "center", gap: "2rem"}}>
        <Link to="/states" className="elecmap">
        <div className="refpic">
          <StaticImage height={240} src="../images/states_1.png" alt="A map of every U.S. state legislature." />
            <div className="mapdim mapwidth">62”</div>
            <div className="mapdim mapheight">36”</div>
          </div>
          <h4>American State Legislatures</h4>
          <p className="linkButton">Fill out the pre-order form</p>
          <p>Prepare to understand the legislative branch of government like you never have before.</p>
          
        </Link>
        <Link to="/cartograms" className="elecmap">
        <div className="refpic">
            <StaticImage height={240} src="../cartograms/canada/canada_2021.png" alt="A grid cartogram of the 2021 Canadian election." />
            <div className="mapdim mapwidth">36”</div>
            <div className="mapdim mapheight">18”</div>
          </div>
          <h4>House of Commons Cartograms</h4>
          <p className="linkButton">Available soon</p>
          <p>Land doesn't vote, and Canada has so very much of it. Federal elections from 1968 to 2021.</p>
          
        </Link>
      </div>

      <h2>Map Portfolio</h2>
      <p>Produced on my own time for my own purposes. Click to open in a new tab.</p>

      <div className="portfolio">

      <a href="/moose_jaw.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../portfolio/moose_jaw.png"
            alt="A map of the Moose Jaw bus system."
          />
          <h4>City of Moose Jaw Transit Map</h4>
        </div></a>
        <a href="/farmland.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../portfolio/farmland.png"
            alt="A map of 2023 farmland values in Canada."
          />
          <h4>Farmland values in Canada, 2023</h4>
        </div></a>
        <a href="/ontario.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../portfolio/ontario.png"
            alt="A map of four 2021 census variables in Ontario."
          />
          <h4>Demographics of Ontario</h4>
        </div></a>

      <a href="/4_hex_population.jpg" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../30dmc/4_hex_population.jpg"
            alt="A hexagonal bin map of Canada's population density."
          />
          <h4>Population of Canada</h4>
        </div></a>
        <a href="/13_hutterites.jpg" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../30dmc/13_hutterites.jpg"
            alt="A map of every Hutterite colony in North America."
          />
          <h4>Hutterite Colonies of North America</h4>
        </div></a>
        <a href="/3_nearest_park.jpg" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../30dmc/3_nearest_park.jpg"
            alt="A map of the nearest national park in Canada."
          />
          <h4>The Nearest National Park</h4>
        </div></a>

        <a href="/india.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../portfolio/india.png"
            alt="A grid cartogram of the 2019 Indian election."
          />
          <h4>India general election, 2019</h4>
        </div></a>
        <a href="/swing.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../portfolio/swing.png"
            alt="An election map of Canada showing change in party preference between 2015 and 2019."
          />
          <h4>Canadian politics: 2015 vs. 2019</h4>
        </div></a>
        <a href="/newfoundland.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../portfolio/newfoundland.png"
            alt="A map of religious affiliation in Newfoundland, 1945."
          />
          <h4>Religion in the Dominion of Newfoundland</h4>
        </div></a>

        <a href="/access.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../portfolio/access.png"
            alt="A map of covid restrictions in Canada."
          />
          <h4>Community-Issued Travel Restrictions</h4>
        </div></a>

        <a href="/missing.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../portfolio/missing.png"
            alt="A poster describing the 'Missing Middle' concept in urban planning."
          />
          <h4>Edmonton's Missing Middle</h4>
        </div></a>
        <a href="/ethnic.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../portfolio/ethnic.png"
            alt="A dot density map of the most common ethnicities reported in Canada."
          />
          <h4>Most Common Ethnicities of Canada</h4>
        </div></a>
        </div>

      <h2>For Paying Clients</h2>
      <p>I can work to your brand guidelines and provide unique data insights: let's talk!</p>

      <div className="portfolio">
      <a href="/minneapolis.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../images/minneapolis.png"
            alt="A visitor orientation map of downtown Minneapolis."
          />
          <h4>Minneapolis Program Map</h4>
          <p>North American Cartographic Information Society</p>
        </div></a>

        <a href="/klemtu.jpg" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../images/klemtu.jpg"
            alt="A map of Klemtu, on the British Columbia Central Coast."
          />
          <h4>Klemtu Territory Patrol</h4>
          <p>The Narwhal</p>
        </div></a>

      <a href="/melfort.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../images/melfort.png"
            alt="A map illustrating the City of Melfort's snow clearance bylaw."
          />
          <h4>Snow Clearance Bylaw Map</h4>
          <p>City of Melfort, Saskatchewan</p>
        </div></a>

        <a href="/bilingualism.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../images/bilingualism.png"
            alt="A map showing bilingualism rates across Canada."
          />
          <h4>English/French bilingual ability</h4>
          <p>Mainstreet / iPolitics</p>
        </div></a>

        <a href="/northern.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../images/northern.png"
            alt="An election map showing the effects of boundary redistribution in Northern Ontario."
          />
          <h4>Boundaries in Northern Ontario</h4>
          <p>Mainstreet / iPolitics</p>
        </div></a>

        <a href="/brampton.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../images/brampton.png"
            alt="An election map of Brampton, Ontario."
          />
          <h4>Brampton mayoral election</h4>
          <p>Mainstreet / iPolitics</p>
        </div></a>

        </div>

<h2>Games</h2>
<p>Because visiting websites should be fun again!</p>

<div className="card"><a href="/game" target="_blank" rel="noopener noreferrer">
<StaticImage
      src="../images/census_game.png"
      alt="A screenshot of Alex's Census Game."
    />
<span className="cardbody"><h4>Alex's Census Game</h4>
<p>How many communities can you name? Freshly updated with 2021 population data. Be warned: this game is both addictive and difficult.</p>
</span>
<span className="cardlink"><a href="/game" target="_blank" rel="noopener noreferrer">Play →</a></span>
</a></div>
      <h2>Fictional Maps</h2>
      <div className="portfolio">
      <a href="/tomorrow_country.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../portfolio/tomorrow_country.png"
            alt="A fictional map of North America."
          />
          <h4>Governmental Freedom in North America, 2068</h4>
        </div></a>
        <a href="/kanamonta.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../portfolio/kanamonta.png"
            alt="A fictional province."
          />
          <h4>Kanamonta Province</h4>
        </div></a>
        <a href="/stv.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../portfolio/stv.png"
            alt="A fictional election map."
          />
          <h4>New Israel provincial election, 2017</h4>
        </div></a>
        <a href="/maverick.jpg" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../portfolio/maverick.jpg"
            alt="A fictional constitutional crisis."
          />
          <h4>Western Canadian sovereignty crisis, 2020</h4>
        </div></a>
        <a href="/mispon.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../portfolio/mispon.png"
            alt="A fictional province."
          />
          <h4>Mispon Province</h4>
        </div></a>
        <a href="/terra_nova.jpg" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../portfolio/terra_nova.jpg"
            alt="A fictional map of Newfoundland."
          />
          <h4>Republic of Terra Nova</h4>
        </div></a>
        <a href="/united.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../portfolio/united.png"
            alt="A fictional county."
          />
          <h4>United Counties of Last Day and Radium</h4>
        </div></a>
        <a href="/yoop.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../portfolio/yoop.png"
            alt="A fictional map of Connecticut's non-existent territorial claims in the Upper Midwest."
          />
          <h4>Territorial History of Connecticut</h4>
        </div></a>
      </div>

      <h2>30 Day Map Challenge</h2>

      <div className="card"><a href="/30dmc" target="_blank" rel="noopener noreferrer">
<StaticImage
      src="../30dmc/22 Air Service North.png"
      alt="A map of airways in Northern Canada."
    />
  <span className="cardbody"><h4>Every November...</h4>
  <p>Is it really possible to design 30 maps in 30 days? See for yourself.</p>
  </span>
  <span className="cardlink"><a className="plainlink" href="/30dmc" target="_blank" rel="noopener noreferrer">My 30DMC gallery →</a></span>
</a></div>

      <h2>Appendix</h2>
      <p>Interested in starting your own map business? <Link to="/print">Here's all of my advice on how to work with a commercial press</Link>.</p>
    </Layout>
  );
}
